<template>
  <div class="import-results-container">
    <b-tabs content-class="mt-3">
      <b-tab title="Import Results" active>
        <div class="m-2">
          <FormulateForm 
            @submit.stop.prevent  
            :values="theFormValues"
             @input="getNewFormValues"
             class="ml-2"  id='gltf-uploads'>
            <FormulateInput 
              accept=".gltf, .glb"
              @input="setAcceptAttribute"
              v-model="file"
              type='file'
              class="custom-upload-area"
              label="Upload Results"
              validation="validateFileType"
              :validation-rules="{ validateFileType}"
              :validation-messages="{
                validateFileType: 'File must be of type gtlf or glb',                 
              }"
              help="Please select a gtlf file type to be upload"
              multiple            
            >
               <template #label="{label, id}">
                <label :for="id" class="h4">
                  {{ label }}
                </label>
              </template>            
            </FormulateInput>
          </FormulateForm>
        </div>
        <div v-if="hasDuplicatesNames">
          <b-alert v-for="(error, index) in duplicateFileNames" :key="index" variant="danger" show dismissible>
            {{ error }} filename is already taken
          </b-alert>
        </div> 
        <div v-if="showTable">
          <div class="d-flex flex-row align-items-center">
            <b-button v-if="disableBulkAction" v-b-tooltip.hover title="Please select files to edit">
              Edit
            </b-button>
            <b-button v-else variant="primary" @click="performBulkAction">
              Edit
            </b-button>
            <div class="d-flex flex-row w-100 justify-content-center">
              <h6>
                Please set meta data for each row so that it uniquely defines each uploaded result
              </h6>
            </div>
          </div>
          <b-table-simple bordered hover>
              <template>
                <col style="width: 3.75em;">
                <col style="width: 6.25em;">
                <!-- divide ("/") by 16 is the conversion of px to em -->
                <col
                  v-for="(value, index) in columnWidths"
                  :key="index"
                  :style="{ width:`${value / 16 }em` }" 
                >
                <col style="width: 5em;">
              </template>
              <b-thead style='background-color: #E9ECEF;'>
                <b-tr>
                  <b-th></b-th>
                  <b-th>Status</b-th>
                  <b-th v-for="(name, index) in colNames" :key="index"> {{ name }} </b-th>
                  <b-th>Remove </b-th>
                </b-tr>
              </b-thead>
              <b-tbody class='bg-white mh-50 overflow-auto'>
                <b-tr v-for="(file, index) in gltfUploads" :key="index" :class="getRowClass(index)" @click="selectRowByIndex(index)">            
                  <b-th> 
                    <b-td class="border-0 p-0"  id="select-checkmark"><FormulateInput v-model="selectedFiles" :key="index"  :value="index" @click="selectRowByIndex(index)" type="checkbox" :wrapper-class="['ml-2']" /></b-td>
                  </b-th>
                  <b-th class='font-weight-light'>
                    <b-icon v-b-tooltip.hover class="ml-sm-3" :title="getIconTitle(index)" :variant="getIconVariant(index)" :icon="getIconType(index)" style="font-size: small;"></b-icon>   
                  </b-th>  
                  <b-th class='font-weight-light' v-on:dblclick="editRow(index)"> 
                    <b-td class="border-0 p-0">{{ file.name }}</b-td>
                  </b-th>
                  <b-th class='font-weight-light' v-on:dblclick="editRow(index)">  
                    <b-td v-if="isMetaDataSet(index)" class="border-0 p-0">{{ file.metaData.layer_type }}</b-td>
                  </b-th>
                  <b-th class='font-weight-light' v-on:dblclick="editRow(index)">  
                    <b-td v-if="isMetaDataSet(index)" class="border-0 p-0">{{ file.metaData.analysis_type }}</b-td>
                  </b-th>
                  <b-th class='font-weight-light' v-on:dblclick="editRow(index)">  
                    <b-td v-if="isMetaDataSet(index)" class="border-0 p-0">{{ file.metaData.wind_direction }}</b-td>
                  </b-th>
                  <b-th class='font-weight-light' v-on:dblclick="editRow(index)">  
                    <b-td  v-if="isMetaDataSet(index)" class="border-0 p-0">{{ file.metaData.metric }}</b-td>
                  </b-th>
                  <b-th class='font-weight-light' v-on:dblclick="editRow(index)">  
                    <b-td v-if="isMetaDataSet(index)" class="border-0 p-0">{{ file.metaData.season }}</b-td>
                  </b-th>
                  <b-th class='font-weight-light' v-on:dblclick="editRow(index)">  
                    <b-td v-if="isMetaDataSet(index)" class="border-0 p-0">{{ file.metaData.time }}</b-td>
                  </b-th>
                  <b-th class='font-weight-light' v-on:dblclick="editRow(index)">  
                    <b-td v-if="isMetaDataSet(index)" class="border-0 p-0">{{ file.metaData.subsurface_type }}</b-td>
                  </b-th>
                  <b-th class='font-weight-light'>
                    <b-td class="border-0 p-0"> {{ getQualifiersText(index) }} </b-td>
                  </b-th>
                  <b-th class='font-weight-light'>   
                    <b-td class="border-0 p-0">  <b-icon font-scale="1.3" v-b-tooltip.hover title="Remove File"  @click="removeFile(index)"  icon="trash" class="mt-1 ml-2"  style="font-size: small;" variant="secondary"></b-icon> </b-td>
                  </b-th>
                </b-tr>
              </b-tbody>
          </b-table-simple>
        </div>
        <b-modal id="studyFileModal" size="lg" @hidden="closingModal" @show="autoPopulateQualifiers">
          <template #modal-title>
            <div class="text-center">
              <h4>Select your Metadata</h4>
            </div>
          </template>
          <template #default>
            <div>
              <b-card no-body>
                <div>
                  <FormulateForm  @submit.stop.prevent class='ml-2'>
                    <div class="required-metadata-fields">
                      <FormulateInput
                        v-model='layerType'
                        :options="layerTypeList"
                        type="select"
                        id="layer-type"
                        label="Layer Type"
                        error-behavior="live"
                        validation="validatelayerTypeList"
                        :validation-rules="{ validatelayerTypeList }"
                        :wrapper-class="['d-flex', 'justify-content-center', 'mt-3']"
                        :element-class="['mt-0','file-select']"
                      >
                        <template #label="{label, id}">
                          <div style="width: 16%;">
                            <label :for="id" class="mt-2">
                              {{ label }}:
                            </label>
                          </div>
                        </template>
                      </FormulateInput>
                    </div>

                    <div v-if="!isAddingCustomUnits('analysisType')" class="required-metadata-fields">
                      <FormulateInput
                        v-model='analysisType'
                        :options="getUnitList('analysisTypeList')"
                        type="select"
                        id="analysis-type"                        
                        label="Analysis Type"
                        error-behavior="live"
                        validation="validateAnalysisTypeList"
                        :validation-rules="{ validateAnalysisTypeList }"
                        :wrapper-class="['d-flex', 'justify-content-center', 'mt-3']"
                        :element-class="['mt-0','file-select']"
                      >
                        <template #label="{label, id}">
                          <div style="width: 16%;">
                            <label :for="id" class="mt-2">
                              {{ label }}:
                            </label>
                          </div>
                        </template>
                      </FormulateInput>
                    </div>
                    <div v-else>
                      <div>
                        <FormulateInput
                          v-model='customField'
                          type="text"
                          id="analysis-type-custom"
                          label="Analysis Type"
                          :wrapper-class="['d-flex',  'justify-content-center','mt-3']"
                          :element-class="['mt-0','file-select', 'd-flex']"
                          @keyup.enter="handleCustomChange('analysisType')"
                        >
                          <template #label="{label, id}">
                            <div style="width: 16%;">
                              <label :for="id" class="mt-2">
                                {{ label }}:
                              </label>
                            </div>
                          </template>
                          <template #suffix>
                            <div class="d-flex flex-column mt-0 "  id="add-custom-criteria-controls">
                              <b-icon icon="x-circle-fill" @click="closeCustomUnits('analysisType')" class="mt-1" style="font-size: small;" variant="danger"></b-icon>
                              <b-icon icon="check-circle-fill" @click="addCustomUnit('analysisType')" class="mb-0 mt-1 " style="font-size: small;" variant="success"></b-icon>
                            </div>
                          </template>
                        </FormulateInput>
                      </div>
                    </div>
                    <FormulateInput
                      v-model='windDirection'
                      type="number"
                      label='Wind Direction'
                      id="wind-Direction"
                      :wrapper-class="['d-flex', 'justify-content-center', 'mt-3']"
                      :element-class="['mt-0', 'file-select']" 
                      min=0
                    >
                      <template #label="{label, id}">
                        <div style="width: 16%;">
                          <label :for="id" class="mt-2">
                            {{ label }}:
                          </label>
                        </div>
                      </template>
                    </FormulateInput>
                    <div v-if="!isAddingCustomUnits('metric')">
                      <FormulateInput
                        v-model='metric'
                        :options="getUnitList('metricList')"
                        type='select'
                        label='Metric Type'
                        id="select-metrics"
                        :wrapper-class="['d-flex', 'justify-content-center', 'mt-3']"
                        :element-class="['mt-0', 'file-select']" 
                      >
                        <template #label="{label, id}">
                          <div style="width: 16%;">
                            <label :for="id" class="mt-2">
                              {{ label }}:
                            </label>
                          </div>
                        </template>
                      </FormulateInput>
                    </div>
                    <div v-else>
                      <div>
                        <FormulateInput
                          v-model='customField'
                          type="text"
                          id="metric-custom"
                          label="Metric"
                          :wrapper-class="['d-flex', 'justify-content-center', 'mt-3']"
                          :element-class="['mt-0','file-select', 'd-flex']"
                          @keyup.enter="handleCustomChange('metric')"
                        >
                          <template #label="{label, id}">
                            <div style="width: 16%;">
                              <label :for="id" class="mt-2">
                                {{ label }}:
                              </label>
                            </div>
                          </template>
                          <template #suffix>
                            <div class="d-flex flex-column mt-0 "  id="add-custom-criteria-controls">
                              <b-icon icon="x-circle-fill" @click="closeCustomUnits('metric')" class="mt-1" style="font-size: small;" variant="danger"></b-icon>
                              <b-icon icon="check-circle-fill" @click="addCustomUnit('metric')" class="mb-0 mt-1 " style="font-size: small;" variant="success"></b-icon>
                            </div>
                          </template>
                        </FormulateInput>
                      </div>
                    </div>
                    <div v-if="!isAddingCustomUnits('presentationSurface')">
                      <FormulateInput
                        v-model='presentationSurface'
                        :options="getUnitList('presentationSurfaceList')"
                        type='select'
                        label='Surface'
                        id="select-presentationSurface"
                        :wrapper-class="['d-flex','justify-content-center',]"
                        :element-class="['mt-0', 'file-select']" 
                      >
                        <template #label="{label, id}">
                          <div style="width: 16%;">
                            <label :for="id" class="mt-2">
                              {{ label }}:
                            </label>
                          </div>
                        </template>
                      </FormulateInput>
                    </div>
                    <div v-else>
                      <div>
                        <FormulateInput
                          v-model='customField'
                          type="text"
                          id="presentationSurface-custom"
                          label="Surface"
                          :wrapper-class="['d-flex','justify-content-center','mt-3']"
                          :element-class="['mt-0','file-select', 'd-flex']"
                          @keyup.enter="handleCustomChange('presentationSurface')"
                        >
                          <template #label="{label, id}">
                            <div style="width: 16%;">
                              <label :for="id" class="mt-2">
                                {{ label }}:
                              </label>
                            </div>
                          </template>
                          <template #suffix>
                            <div class="d-flex flex-column mt-0 "  id="add-custom-criteria-controls">
                              <b-icon icon="x-circle-fill" @click="closeCustomUnits('presentationSurface')" class="mt-1" style="font-size: small;" variant="danger"></b-icon>
                              <b-icon icon="check-circle-fill" @click="addCustomUnit('presentationSurface')" class="mb-0 mt-1 " style="font-size: small;" variant="success"></b-icon>
                            </div>
                          </template>
                        </FormulateInput>
                      </div>
                    </div>
                    <div v-if="!isAddingCustomUnits('season')" class="d-flex justify-content-center">
                      <FormulateInput
                        v-model='season'
                        :options="getSeasonsOrTime('season')"
                        type='select'
                        label='Season'
                        id="select-season"
                        :element-class="['mt-0', 'time-season-width','custom-input-name']" 
                      />
                      <FormulateInput v-model="seasonStart"  name="Start" type="select" label="Season Start" :options="monthList" class="ml-3 error-below mt-0" :element-class="['mt-0', 'time-season-width']"/>
                      <FormulateInput v-model="seasonEnd" name="End" type="select" label="Season End" :options="monthList" class="ml-3 error-below d-flex mt-0 " :element-class="['mt-0', 'd-flex', 'time-season-width']"/>
                    </div>
                    <div v-else >
                      <div class="d-flex justify-content-center" >
                        <FormulateInput
                            v-model='customField'
                            type="text"
                            id="season-custom"
                            label="Name"
                            :wrapper-class="['mt-0']"
                            :element-class="['mt-0', 'time-season-width']"
                            class="custom-wrapper"
                            @keyup.enter="addCustomSeason('season')"
                            validation="validateCustomName"
                            :validation-rules="{ validateCustomName }"
                            :validation-messages="{
                              validateCustomName: 'Name can only contain letters numbers and dashes',                 
                            }"
                          >
                          </FormulateInput>
                          <FormulateInput v-model="customSeasonStart" name="Start" type="select" label="Season Start" :options="monthList" class="ml-3 my-0 error-below" :element-class="['m-0']"/>
                          <FormulateInput v-model="customSeasonEnd" name="End" type="select" label="Season End" :options="monthList" class="ml-3 my-0 error-below d-flex" :element-class="['m-0', 'd-flex', 'time-season-width']"> 
                            <template #suffix>
                              <div class="d-flex flex-column mt-0 "  id="add-custom-criteria-controls">
                                <b-icon icon="x-circle-fill" @click="closeCustomUnits('season')" class="mt-1" style="font-size: small;" variant="danger"></b-icon>
                                <b-icon icon="check-circle-fill" @click="addCustomSeason('season')" class="mb-0 mt-1 " style="font-size: small;" variant="success"></b-icon>
                              </div>
                            </template>
                        </FormulateInput>
                      </div>
                    </div>
                    <div v-if="!isAddingCustomUnits('time')" class="d-flex mt-0 justify-content-center">
                      <FormulateInput
                        v-model='time'
                        type='select'
                        label='Time'
                        id="select-time"
                        :options="getSeasonsOrTime('time')" 
                        :element-class="['mt-0','time-season-width', 'justify-content-center']" 
                      />
                      <FormulateInput v-model="timeStart" name="Start" type="select" label="Time Start" :options="customTimeList" class="ml-3 error-below mt-0" :element-class="['mt-0','time-season-width']"/>
                      <FormulateInput v-model="timeEnd" name="End" type="select" label="Time End" :options="customTimeList" class="ml-3 error-below d-flex mt-0 " :element-class="['mt-0', 'd-flex', 'time-season-width']"/>
                    </div>
                    <div v-else class="mt-0">
                      <div class="mt-0 d-flex justify-content-center">                       
                        <FormulateInput
                            v-model='customTimeValue'
                            type="text"
                            id="time-custom"
                            label="Name"
                            :wrapper-class="['mt-0']"
                            :element-class="['mt-0','custom-input-name', 'time-season-width']"
                            class="custom-wrapper"
                            @keyup.enter="addCustomTime('time')"
                            validation="validateCustomName"
                            :validation-rules="{ validateCustomName }"
                            :validation-messages="{
                              validateCustomName: 'Name can only contain letters numbers and dashes',                 
                            }"
                          >
                          </FormulateInput>
                          <FormulateInput v-model="customTimeStart" name="Start" type="select" label="Time Start" :options="customTimeList" class="ml-3 my-0 error-below" :element-class="['m-0', 'time-season-width']"/>
                          <FormulateInput v-model="customTimeEnd" name="End" type="select" label="Time End" :options="customTimeList" class="ml-3 my-0 error-below d-flex" :element-class="['m-0', 'd-flex', 'time-season-width', 'time-wrapper']"> 
                            <template #suffix>
                              <div class="d-flex flex-column mt-0 "  id="add-time-close">
                                <b-icon icon="x-circle-fill" @click="closeCustomUnits('time')" class="mt-1" style="font-size: small;" variant="danger"></b-icon>
                                <b-icon icon="check-circle-fill" @click="addCustomTime('time')" class="mb-0 mt-1 " style="font-size: small;" variant="success"></b-icon>
                              </div>
                            </template>
                        </FormulateInput>
                      </div>
                    </div>
                    <div class="d-flex mt-3 justify-content-center">                       
                      <div class="mt-0">
                        <FormulateForm class='ml-1'   @submit.prevent  >
                          <div class="d-flex mb-1 py-2 pl-1 align-items-end border-dark border rounded">
                            <FormulateInput 
                              v-model="customQualifierKey" 
                              name="Key" 
                              type="text" 
                              label="Key" 
                              class="my-0 error-below d-flex" 
                              :element-class="['m-0']"
                              style="max-width: 9.5em;"
                            />
                            <FormulateInput 
                              v-model="customQualifierValue"
                              name="Value" 
                              type="text"
                              label="Value" 
                              class="ml-2 my-0 error-below d-flex" 
                              :element-class="['m-0']"
                              :wrapper-class="['mr-1']"
                              style="max-width: 9.5em;"
                            />    
                          <div id="add-qualifers-heading" class="d-flex flex-column align-items-center mt-0"> 
                            <b-button v-if="qualifiersAreValid" @click='addQualifiers()' class="mt-1 mr-1" variant="primary" v-b-tooltip.hover title="Add">Add</b-button>
                            <b-button v-else class="mt-1 mr-1"  variant="secondary" v-b-tooltip.hover :title=getQErrorMsg>Add</b-button>    
                          </div>                    
                          </div>
                        </FormulateForm>
                      </div>
                    </div>
                    <div class="mt-2" style="margin: auto 25%;" v-if="showQualifiersList">  
                      <b-table-simple hover bordered>
                        <template>
                          <col :style="`width: ${getQKeyLength}em;`">
                          <col :style="`width: ${getQValueLength}em;`">
                          <col style="width: 1.15em;">
                        </template>
                        <b-thead>
                          <b-tr>
                            <b-th class="small py-2 px-3 font-weight-bold">Key</b-th>
                            <b-th class="small py-2 px-3 font-weight-bold">Value</b-th>
                            <b-th class="small py-2 px-3 font-weight-bold"></b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody class='bg-white mh-50 overflow-auto'>
                          <b-tr v-for="(file, index) in getQualifiers" :key="index">
                            <b-th  class="small py-2 px-3"> 
                              {{ file[0] }}
                            </b-th> 
                            <b-th  class="small py-2 px-3"> 
                              {{ file[1] }}
                            </b-th> 
                            <b-th class='font-weight-light py-1'> 
                               <b-td class="border-0 p-0">  <b-icon @click="deleteQualifier(index)"  font-scale="1.2" v-b-tooltip.hover title="Remove File"  icon="trash" class="mt-1 ml-2"  style="font-size: small;" variant="secondary"></b-icon> </b-td>
                            </b-th> 
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </div>

                  </FormulateForm>
              </div>
              </b-card>      
            </div>
          </template>
          <template #modal-footer="{ cancel }">
            <div class="d-flex justify-content-between">
              <b-button size="sm" variant="danger" @click="cancel()">
                Cancel
              </b-button>
              <b-button size="sm" variant="primary" @click="submitGLTFDetails" v-if="!metaDataHasErrors && !isAddingSeasons && !isAddingTimes && !isAddingCustomField">
                Update
              </b-button>
              <b-button v-b-tooltip.hover :title="getFormErrors" size="sm" class="btn-secondary" v-else>
                Update
              </b-button>
            </div>
          </template>
        </b-modal> 
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from '@/network/eventbus';

export default {
  name: 'ImportResultsFile',
  data() {
    return {
      duplicateQualifiers: false,
      fields: ['key ', 'value'],
      qualifiersArray: [],
      addingQualifiers: false,
      file: false,
      customField: '', 
      customQualifierKey: '',
      customQualifierValue: '', 
      timeList: ['Morning', 'Midday',  'Evening'], 
      seasonList: ['Annual', 'Spring', 'Summer', 'Autumn', 'Winter'],
      metricList: ['Abridged Wind Comfort Criteria','Solar Exposure','Ventilation Potential','SPMV*','SPMV % Time Acceptable','SPMV % Time Comfortable','UTCI','UTCI Inbounds','Wind Intesity'],
      analysisTypeList: ['Directional Wind','Pedestrian Wind Comfort','Solar Analysis','Thermal Comfort'],
      layerTypeList: ['Horizontal', 'Volumetric'],
      monthList: {1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September',
        10: 'October', 11: 'November', 12: 'December'},
      customTimeList: {'00:00:00': '12:00 AM', '01:00:00': '1:00 AM', '02:00:00': '2:00 AM', '03:00:00': '3:00 AM', '04:00:00': '4:00 AM', '05:00:00': '5:00 AM', '06:00:00': '6:00 AM', '07:00:00': '7:00 AM',
        '08:00:00': '8:00 AM', '09:00:00': '9:00 AM', '10:00:00': '10:00 AM', '11:00:00': '11:00 AM', '12:00:00': '12:00 PM', '13:00:00': '1:00 PM', '14:00:00': '2:00 PM', '15:00:00': '3:00 PM',
        '16:00:00': '4:00 PM', '17:00:00': '5:00 PM', '18:00:00': '6:00 PM', '19:00:00': '7:00 PM', '20:00:00': '8:00 PM', '21:00:00': '9:00 PM', '22:00:00': '10:00 PM', '23:00:00': '11:00 PM'},
      presentationSurfaceList: ['Study', 'Surrounds', 'Ground','Balcony','Terrace', 'Roof'],
      layerType: null,
      analysisType: null,
      metric: null,
      presentationSurface: null,
      time: null,
      windDirection: null,
      season: null,
      gltfUploads: [],
      selectedFiles: [],
      filesWithMetaData: [],
      customSeasonsObject: {},
      customTimeObject: {},
      customUnitObject: {},
      customSeasonStart: null,
      customSeasonEnd: null,
      customTimeValue: '',
      customTimeStart: null,
      customTimeEnd: null,
      currentCustomKey: '',
      validUploads: [],
      duplicatesList: [],
      fileNames: [],
      duplicateFileNames: [],
      customTimeRange: { 
        Morning: {
          'name': 'Morning',
          'start_time': '06:00:00',
          'end_time': '11:00:00'
        }, 
        Midday: {
          'name': 'Midday',
          'start_time': '11:00:00',
          'end_time': '14:00:00'
        },
        Evening: {
          'name': 'Evening',
          'start_time': '14:00:00',
          'end_time': '19:00:00'
        },  
      },
      customSeasonRange: {
        Spring: {
          'name': 'Spring',
          'start_month': '3',
          'end_month': '5'
        }, 
        Summer: {
          'name': 'Summer',
          'start_month': '6',
          'end_month': '8'
        },
        Autumn: {
          'name': 'Autumn',
          'start_month': '8',
          'end_month': '10'
        },
        Winter: {
          'name': 'Winter',
          'start_month': '10',
          'end_month': '12'
        },
        Annual: {
          'name': 'Annual',
          'start_month': '1',
          'end_month': '12'
        },
      },
      seasonStart: null,
      seasonEnd: null,
      timeStart: null,
      timeEnd: null,
      addingSeasons: false,
      addingTime: false,
      populatedFields: {},
      qKey: false,
      qVal: false,
      columnWidths: [],
      qualifiersMap: {},
      colNames: ['File Name', 'Layer Type', 'Analysis Type','Wind Directions', 'Metric', 'Season', 'Time', 'Presentation Surface','Qualifiers',],
      columnParamNames: ['fileName', 'layer_type', 'analysis_type','wind_direction', 'metric', 'season', 'time', 'presentationSurface', 'qualifiers'],
      dbClicked: null,
      copiedSelectedFiles: [],
      formattedQualifiers: {},
      colorMap: [],
    };
  },
  mounted() {
    this.getAnalysisTypes();
    this.getCriteriaTypes();
    this.inititaiteColsWidth();
    // const colorMap = [{
    //   'Name': 'Relative Wind Speed',
    //   'Points': [
    //     0.0,
    //     1.0,
    //     0.5,
    //     0.0,
    //     7.8,
    //     1.0,
    //     0.5,
    //     0.0
    //   ],
    //   'RGBPoints': [
    //     0.0,
    //     0.0,
    //     0.2235294117647059,
    //     0.3686274509803922,
    //     0.78,
    //     0.00392156862745098,
    //     0.45098039215686278,
    //     0.7254901960784314,
    //     1.56,
    //     0.0,
    //     0.6823529411764706,
    //     0.9372549019607843,
    //     2.34,
    //     0.5725490196078431,
    //     0.7725490196078432,
    //     0.8705882352941177,
    //     3.12,
    //     0.8196078431372549,
    //     0.8980392156862745,
    //     0.9411764705882353,
    //     3.9,
    //     1.0,
    //     1.0,
    //     0.7490196078431373,
    //     4.68,
    //     0.996078431372549,
    //     0.8784313725490196,
    //     0.5647058823529412,
    //     5.46,
    //     0.9921568627450981,
    //     0.6823529411764706,
    //     0.3803921568627451,
    //     6.24,
    //     0.9450980392156863,
    //     0.36470588235294118,
    //     0.13333333333333334,
    //     7.02,
    //     0.8431372549019608,
    //     0.18823529411764707,
    //     0.15294117647058826,
    //     7.8,
    //     0.6470588235294118,
    //     0.0,
    //     0.14901960784313726
    //   ],
    //   'ColorSpace': 'RGB',
    //   'Annotations': [
    //     0.0,
    //     'Low',
    //     3.9,
    //     'Medium',
    //     7.8,
    //     'High'
    //   ],
    //   'PlotDescription': 'Relative Wind Intensity'
    // }];
    // this.colorMap = colorMap;
  },
  computed: { 
    getFormErrors(){
      let errorString = 'Errors: '; 
      if(this.layerType ==null)
        errorString += ' LayerType';
      if(this.analysisType ==null)
        errorString += ', AnalysisType';
      return errorString;
    },
    showQualifiersList(){
      return this.qualifiersArray.length;
    },
    getQValueLength(){
      let max = 5; // "value" has 5 characters 
      let characters =  this.qualifiersArray;
      characters.forEach(character => {
        if (character.value.length > max) {
          max = Number(character.value.length);
        }
      });
      return (max * 9.5 ) / 16;
    },
    getQKeyLength(){
      let max = 3; // "key" has 3 characters  
      let characters =  this.qualifiersArray;
      characters.forEach(character => {
        if (character.key.length > max) {
          max = character.key.length;
        }
      });
      return (max * 9.5 ) / 16;
    },
    getQualifiers(){
      let qualifiersArray = this.qualifiersArray; 
      var sortedArray = qualifiersArray.map(function (obj) {
        return [obj.key, obj.value]; 
      });
      return sortedArray;
    },
    isAddingQualifiers(){
      return this.addingQualifiers;
    },
    getQErrorMsg() {
      if(!this.qKey && !this.qVal)
        return 'Enter a key and a value';
      if(!this.qKey)
        return 'Qualifier key is not valid';
      if(!this.qVal)
        return 'Qualifier value is not valid';
      return 'Enter a key and a value';
    },
    qualifiersAreValid(){
      if(this.qKey && this.qVal)
        return true;
      return false;
    },
    isValid(){
      if(this.duplicatesList.length == 0 && this.gltfUploads.length == this.validUploads.length)
        return true;
      return false;
    },
    isAddingSeasons(){
      return this.addingSeasons;
    },
    isAddingCustomField(){
      return this.currentCustomKey == '' ? false : true;
    },
    isAddingTimes(){
      return this.addingTime;
    },
    hasDuplicatesNames() {
      return this.duplicateFileNames.length > 0 ? true : false;
    },
    theFormValues: {
      get() {
        return this.formValues;
      },
      set(newFormValues) {
        return newFormValues;
      }
    },           
    disableBulkAction() {
      return this.selectedFiles.length > 0 ? false : true;
    },
    showTable(){
      return this.gltfUploads.length > 0 ? true : false;
    },
    metaDataHasErrors() {
      if(
        this.analysisType &&
        this.layerType
      )
        return false;
      return true;
    },
    ...mapGetters('project', ['createScenarioStep', 'createScenarioBtnClicked', 'backBtnClicked', 'selectedProject', 'jobTypes', 'criteria', 'scenarioSubmissionInProgress']),
  },
  methods: {

    getQualifiersText(index){
      if(this.gltfUploads[index] && this.gltfUploads[index]['qualifiers']){
        return this.gltfUploads[index]['qualifiers'];
      }
      return '';
    },
    selectRowByIndex(index){
      // remove index - unselect row
      let selectedFiles = this.selectedFiles;
      if(selectedFiles.includes(index)){
        let indexOfSelected = selectedFiles.indexOf(index);
        if (indexOfSelected !== -1) {
          selectedFiles.splice(indexOfSelected, 1);
        }
        this.selectedFiles = selectedFiles;
      // add index - select row
      }else{
        selectedFiles.push(index);
        this.selectedFiles = selectedFiles;
      }    
    },
    validatelayerTypeList(){
      return this.layerTypeList.includes(this.layerType);
    },
    validateAnalysisTypeList(){
      const list = this.getUnitList('analysisTypeList');
      return list.includes(this.analysisType);
    },
    getFilesWithMetaData(){
      let uploads = this.gltfUploads;
      let filesWithMetaData = []; 
      for (let index = 0; index < uploads.length; index++) {
        if(this.selectedFiles.includes(index)){
          const hasMetaData = uploads[index]?.metaData;
          if(hasMetaData)
            filesWithMetaData.push(index);
        }
      }
      return filesWithMetaData; 
    },
    getFilesWithQualifiers(){
      const filesWithMetaData = this.getFilesWithMetaData();  
      const uploads = this.gltfUploads;  
      let filesWithQualifiers = [];
      // Logic to handle more than one
      for (let index = 0; index < filesWithMetaData.length; index++) {
        let currentIndex = filesWithMetaData[index];    
        if(uploads[currentIndex]['manifest'] && uploads[currentIndex]['manifest']['qualifiers']){
          filesWithQualifiers.push(currentIndex);      
        }
      }
      return filesWithQualifiers;
    },
    populatedMutliQFields(){
      const filesWithQualifiers = this.getFilesWithQualifiers();
      const uploads = this.gltfUploads; 
      let populatedFields = {};
      // Logic for multiple selected but on has one qualifier
      if(filesWithQualifiers.length == 1){
        const selectedFile = uploads[filesWithQualifiers[0]];
        const populatedFields = selectedFile['manifest']['qualifiers'];
        let currentQualifiersArray = [];
        for (const [key, value] of Object.entries(populatedFields)) {
          const entry = { key: key, value: value };
          currentQualifiersArray.push(entry);
        }
        this.qualifiersArray = currentQualifiersArray;
        return;
      }
      //Logic for multiple files with Qualifiers
      for (let index = 0; index < filesWithQualifiers.length; index++) {
        // there must be at least 2 files with qualifiers for comparison of auto-populated fields to calcualted
        if(index > 0){
          const currentIndex = filesWithQualifiers[index];
          let  currentQualifierWithData = uploads[currentIndex];
          let keysWithSameValues = {};
          if(currentQualifierWithData['manifest'] && currentQualifierWithData['manifest']['qualifiers']){          
            currentQualifierWithData = currentQualifierWithData['manifest']['qualifiers'];
            const lastIndex = filesWithQualifiers[index - 1];
            let lastQualifierWithData= uploads[lastIndex]['manifest']['qualifiers'];
            keysWithSameValues = this.getKeysWithSameValue(lastQualifierWithData,currentQualifierWithData);
            // if keysWithSameValue isnt empty and its the start of the loop
            if(keysWithSameValues.length > 0  && index == 1 ){
              //convert the array of objects to one large object
              let initialpopulatedFields = {};
              for (let keyIndex = 0; keyIndex < keysWithSameValues.length; keyIndex++) {
                const currentObject = keysWithSameValues[keyIndex];
                initialpopulatedFields = {...initialpopulatedFields, ...currentObject};
              }
              //set matching keys
              populatedFields = initialpopulatedFields;
            }
          }
          // delete the keys that dont match from the previous qualifier
          if(keysWithSameValues.length > 0  && index != 1 ){
            for (let keyIndex = 0; keyIndex < keysWithSameValues.length; keyIndex++) {
              const currentObject = keysWithSameValues[keyIndex];
              let currentObjectKey = Object.keys(currentObject);
              currentObjectKey = currentObjectKey[0];
              if(populatedFields[currentObjectKey] !== undefined){
                if(currentObject[currentObjectKey] != populatedFields[currentObjectKey]){
                  delete populatedFields[currentObjectKey];
                }
              }
            }
          }
        }
      }
      let currentQualifiersArray  = [];
      for (const [key, value] of Object.entries(populatedFields)) {
        const entry = { key: key, value: value };
        currentQualifiersArray.push(entry);
      }
      this.qualifiersArray = currentQualifiersArray;
    },
    initiateMetaData(){
      // Only one file is selected and has metaData
      let filesWithMetaData = this.getFilesWithMetaData();
      if(filesWithMetaData.length == 1 && this.selectedFiles.length == 1 ){
        this.populateSingle();
      }

      // More than one file was selected with metaData 
      const selected =  this.selectedFiles;
      let filesSelectedWithMetaData = 0;
      for (let index = 0; index < filesWithMetaData.length; index++) {
        const element = filesWithMetaData[index];
        if(selected.includes(element))
          filesSelectedWithMetaData = +1;
      }
      if(filesSelectedWithMetaData > 1 || (filesSelectedWithMetaData >= 1 && selected.length > 1))
        this.populateMultiple();
    },
    initiateQualifiers(){
      if(this.selectedFiles.length == 1){
        this.createQListForSingle();
      }

      if(this.selectedFiles.length > 1)
        this.populatedMutliQFields();     
    },
    createQListForSingle(){
      let selectedIndex = this.selectedFiles[0];
      if(this.dbClicked != null) 
        selectedIndex = this.dbClicked;
      const uploads =  this.gltfUploads;
      const qualifiers = uploads[selectedIndex]?.['manifest']?.['qualifiers'];
      let qualifiersArray = [];
      if(qualifiers != undefined){        
        for (const [key, value] of Object.entries(qualifiers)) {
          const entry = { key: key, value: value };
          qualifiersArray.push(entry);
        }
      }
      this.qualifiersArray = qualifiersArray;
    },
    autoPopulateQualifiers(){
      this.initiateMetaData();
      this.initiateQualifiers();
    },
    formatQualifiers(){
      const qualifiers = this.qualifiersArray;
      let formattedQualifiers = {};
      for (let qualifier of qualifiers){
        formattedQualifiers[qualifier['key']] = qualifier['value']; 
      }
      return formattedQualifiers;
    },
    closingModal(){ 
      if(this.dbClicked != null){
        this.dbClicked = null;
      }
      this.selectedFiles = this.copiedSelectedFiles;
      this.copiedSelectedFiles = [];
      this.qualifiersArray = [];
      this.resetModalState();
    },
    editRow(index){
      this.dbClicked = index;
      if(this.dbClicked != null){ 
        this.copiedSelectedFiles = this.selectedFiles;
        this.selectedFiles = [this.dbClicked];           
        const uploads = this.gltfUploads;
        const result = uploads[this.dbClicked];
        if(result.metaData){
          this.initializePopulatedFields(result.metaData);
        }else{
          this.resetModalState();
        }
      }
      this.$bvModal.show('studyFileModal');
    },
    inititaiteColsWidth(){
      let colNames = this.colNames;
      let colWidths = [];
      for (let columnName of colNames) {
        const scalingFactor = 12;
        let columnLength = columnName.length * scalingFactor;
        // if column name length is less than 7 set to  7 
        if(columnName.length < 7)
          columnLength = 7 * scalingFactor;
        colWidths.push(Number(columnLength.toFixed(0)));
      }
      this.columnWidths = colWidths;
    },
    deleteQualifier(index){
      let qualifiersArray = this.qualifiersArray;
      qualifiersArray.splice(index, 1);
      this.qualifiersArray = qualifiersArray;
    },
    closeQForm(){
      this.addingQualifiers = false;
      this.customQualifierKey = '';
      this.customQualifierValue = '';
    },
    showQualifierForm(){
      this.addingQualifiers = true;
    },
    getAnalysisTypes(){
      const jobTypes = this.jobTypes;  
      let allAnalysisTypes = [];
      for (let index = 0; index < jobTypes.length; index++) {
        const element = jobTypes[index].analysis_type;
        if(!allAnalysisTypes.includes(element))
          allAnalysisTypes.push(element);
      }
      this.analysisTypeList = allAnalysisTypes;
    },
    getCriteriaTypes(){
      const criteria = this.criteria;  
      let allMetricTypes = [];
      for (let index = 0; index < criteria.length; index++) {
        const element = criteria[index].name;
        if(!allMetricTypes.includes(element))
          allMetricTypes.push(element);
      }
      this.metricList = allMetricTypes; 
    },
    addQualifiers(){
      const customQualifierKey = this.customQualifierKey;
      const customQualifierValue =  this.customQualifierValue;
      const allQualifiers = this.qualifiersArray;
      const checkQKey = obj => obj.key === customQualifierKey;
      let canAddQualifier = true;

      if(allQualifiers.some(checkQKey) || !canAddQualifier){
        this.customUnitAlertEvents(customQualifierKey, 'duplicate');
      }else{
        let items = allQualifiers;
        const entry = { key: customQualifierKey, value: customQualifierValue, indexes: this.selectedFiles };
        items.push(entry);
        this.qualifiersArray = items;
        this.customUnitAlertEvents(customQualifierKey, 'new');    
        this.customQualifierKey = this.customQualifierValue = '';
        this.addingQualifiers = false;
      }
    },
    calcColumnWidth(textLength, columnName){
      const index = this.columnParamNames.indexOf(columnName);
      const scalingFactor = 10.3;
      textLength = textLength * scalingFactor;
      //if the length of text longer than the minimun length then increase the column via scaling Factor
      if(textLength > this.columnWidths[index]){
        this.columnWidths[index] = textLength;
      }
    },
    getValidUploads(){
      const  gltfUploads = this.gltfUploads;
      let validUploads = [];
      for (let i = 0; i < gltfUploads.length; i++){
        if(gltfUploads[i]['qualifiers'] && gltfUploads[i]['qualifiers'].length > 0){
          const length = gltfUploads[i]['qualifiers'].length;
          this.calcColumnWidth(length, 'qualifiers');       
        }
        if(gltfUploads[i].metaData){
          const list = ['fileName', 'layer_type', 'analysis_type', 'wind_direction', 'metric', 'season', 'time', 'subsurface_type', 'qualifiers'];
          const keys = Object.keys(gltfUploads[i].metaData); 
          for (let index = 0; index < keys.length; index++) {
            let element = keys[index];
            if (list.includes(element)){     
              const columnEntryLength = gltfUploads[i].metaData[element]?.length;  
              if(element == 'subsurface_type') element = 'presentationSurface';
              this.calcColumnWidth(columnEntryLength, element);       
            }
          }
          // Filename is not apart of the metadata...
          this.calcColumnWidth(gltfUploads[i].name.length, 'fileName');
          validUploads.push(i);
        }
      }
      return validUploads;
    },
    removeFile(index) {
      let allFiles = this.gltfUploads;
      let fileNames =  this.fileNames;
      let duplicates = this.duplicatesList;
      allFiles.splice(index, 1);
      fileNames.splice(index, 1);      
      duplicates = duplicates.filter(item => !(item > index));
      this.gltfUploads = allFiles;
      this.fileNames = fileNames;
      this.duplicatesList = duplicates;
      const file = this.file.files[index];
      file.removeFile();
      this.calculateSubmitStatus();
    },
    validateCustomName() {
      const customField = this.customField.trim();
      let onlyLetterNumbersDashes = /^[A-Za-z0-9-]+$/.test(customField);
      if(!onlyLetterNumbersDashes){
        this.$forceUpdate();
        return false;
      }
      if (this.customField.indexOf('_') > -1){
        this.$forceUpdate();
        return false;
      }
      return true;
    },
    async getNewFormValues(data) {
      this.newFormValues = data;
    },
    validateQualifierEntry(customField){
      const onlyLetterNumbersDashes = /^[A-Za-z0-9- ]+$/.test(customField);
      return onlyLetterNumbersDashes;
    },
    async setAcceptAttribute() {
      await this.$nextTick();
      const multiFileInputConrols = document.getElementsByClassName('formulate-file-add-input');
      for (let element of multiFileInputConrols) {
        element.setAttribute('accept', '.gltf');
      }
    },
    getIconTitle(index) {
      if(this.isMetaDataSet(index) && !this.duplicatesList.includes(index))
        return 'Upload Successful';
      if(this.duplicatesList.includes(index))
        return 'Duplicate Row';
      return 'Meta Data is not set';
    },
    getIconVariant(index) {
      if(this.isMetaDataSet(index) && !this.duplicatesList.includes(index))
        return 'success';
      if(!this.duplicatesList.includes(index))
        return 'danger';
      return 'warning';
    },
    getIconType(index) {  
      if(this.isMetaDataSet(index) && !this.duplicatesList.includes(index)){
        if(!this.validUploads.includes(index))
          this.validUploads.push(index);
        return 'check-circle-fill';
      }
      if(this.duplicatesList.includes(index))
        return 'exclamation-triangle-fill';
      return 'exclamation-circle-fill';
    },
    getRowClass(index) {
      let bgColor = this.duplicatesList.includes(index) ? 'meta-row bg-danger' : 'meta-row bg-transparent';
      let selected = this.selectedFiles.includes(index) ? ' selected-row' : '';
      return bgColor + selected;
    },
    mapQualifiers(){
      let totalQualifier = this.qualifiersMap;
      let qualifiersArray = this.qualifiersArray;
      qualifiersArray = qualifiersArray.sort(function(a,b){
        let x = a.key.toLowerCase();
        let y = b.key.toLowerCase();
        if(x>y){return 1;} 
        if(x<y){return -1;}
        return 0;
      });
      qualifiersArray.forEach((item) => {
        const indexes = item.indexes;
        if(indexes){        
          indexes.forEach((index) => {
            if(!totalQualifier[index]){
              totalQualifier[index] = `${item.key} : ${item.value}`;
            }else{
              totalQualifier[index] = `${totalQualifier[index]}, ${item.key} : ${item.value}`;
            }
          });
        }
      });
      this.qualifiersMap = totalQualifier;
      let uploads = this.gltfUploads;
      for (let index = 0; index < uploads.length; index++) {
        if(totalQualifier[index]){
          uploads[index]['qualifiers'] = totalQualifier[index];
        }
      }
      this.gltfUploads = uploads;
    },
    checkUniqueValues() { 
      let gltfFiles = this.gltfUploads;
      let duplicatesList = [];
      let allHashes = [];
      for (let index = 0; index < gltfFiles.length; index++) {     
        let metaDataHash = '';
        let qualifiers = '';
        if(gltfFiles[index]['metaData']){
          const metaData = gltfFiles[index]['metaData'];
          let isInitialized = false;
          Object.entries(metaData).forEach(function(item) {
            if(isInitialized)
              metaDataHash +=` , ${item[0]} : ${item[1]}`;
            else  
              metaDataHash +=`${item[0]} : ${item[1]}`;
            isInitialized = true;
          });
          if(gltfFiles[index]['qualifiers'])
            qualifiers = gltfFiles[index]['qualifiers'];
        }
        let unifiedHash = '';
        if(metaDataHash.length > 0 && qualifiers.length > 0){
          unifiedHash = `${metaDataHash} , ${qualifiers}`;
        }else if(metaDataHash.length > 0 && qualifiers.length < 1){
          unifiedHash = `${metaDataHash}`;  
        }else if(metaDataHash.length < 1 && qualifiers.length > 0){
          unifiedHash = `${qualifiers}`;          
        }
        if(!allHashes.includes(unifiedHash)){
          allHashes.push(unifiedHash);
        }else{
          duplicatesList.push(index);
        }
        gltfFiles[index]['unifiedHash'] = unifiedHash;
      }
      this.gltfUploads = gltfFiles;
      this.duplicatesList = duplicatesList;
      this.selectedFiles = [];
    },
    customUnitAlertEvents(unit, type){
      let variant, content = null;
      let customUnit = unit.charAt(0).toUpperCase() + unit.slice(1);
      switch (type) {
      case 'invalid':
        variant = 'danger'; 
        content = 'Only letters, numbers and dashes are allowed';
        break;
      case 'duplicate':
        variant = 'danger';
        content = `${customUnit} already exists`;
        break;
      case 'missing':
        variant = 'warning';
        content = 'Please select start and a end for this custom field';
        break;
      case 'name':
        variant = 'danger';
        content = 'Custom field name is required';
        break;
      default:
        variant = 'success';
        content = `${customUnit} was created`;
        break;
      }
      EventBus.$emit('TOAST', {
        variant: variant,
        content: content
      });
    },
    addCustomUnit(unit){
      let customUnitObject = this.customUnitObject;
      const value = this.customField.toLocaleLowerCase();
      if(value.length > 0){
        if(customUnitObject[unit] ){
          let existingCustomFields = customUnitObject[unit].split('_');
          if(!existingCustomFields.includes(value)){
            customUnitObject[unit] = `${customUnitObject[unit]}_${value}`;

            this.customUnitAlertEvents(value, 'new');
          }else{
            this.customUnitAlertEvents(value, 'duplicate');
          }
          
        }else{        
          customUnitObject[unit] = value;
          this.customUnitAlertEvents(value, 'new');
        }
        this.$data[unit] = value;
        this.customUnitObject = customUnitObject;
        this.customField = '';
        this.currentCustomKey = '';
      }else{        
        if(value.length == 0)
          this.customUnitAlertEvents(this.customField, 'name');
      }
    },
    addCustomSeason(unit){
      const value = this.customField.toLocaleLowerCase();
      let customSeasonsObject = this.customSeasonsObject;
      if(this.customSeasonStart != null && this.customSeasonEnd != null && value.length > 0){
        if(customSeasonsObject[unit]){
          let existingCustomFields = customSeasonsObject[unit].split('_');
          if(!existingCustomFields.includes(value)){
            customSeasonsObject[unit] = `${customSeasonsObject[unit]}_${value}`;
            const season = { name: value,  start_month: this.customSeasonStart,  end_month: this.customSeasonEnd };
            this.customSeasonRange[value] = season;
            this.customUnitAlertEvents(value, 'new');
          }else{
            this.customUnitAlertEvents(value, 'duplicate');
          }          
        }else{        
          customSeasonsObject[unit] = value;
          const season = { name: value,  start_month: this.customSeasonStart,  end_month: this.customSeasonEnd};
          this.customSeasonRange[value] = season;
          this.customUnitAlertEvents(value, 'new');
        }
        this.$data[unit] = value;
        this.customSeasonsObject = customSeasonsObject;
        this.customField = '';
        this.currentCustomKey = '';
      }else{        
        if(this.customSeasonStart == null || this.customSeasonEnd == null)
          this.customUnitAlertEvents(value, 'missing');
        if(value == 0)
          this.customUnitAlertEvents(value, 'name');
      }
    },
    addCustomTime(unit){
      const value = this.customTimeValue.toLocaleLowerCase();
      let customTimeObject = this.customTimeObject;
      if(this.customTimeStart != null && this.customTimeEnd != null && value.length > 0){
        if(customTimeObject[unit]){
          let existingCustomFields = customTimeObject[unit].split('_');
          if(!existingCustomFields.includes(value)){
            customTimeObject[unit] = `${customTimeObject[unit]}_${value}`;
            const time = { name: value,  start_time: this.customTimeStart,  end_time: this.customTimeEnd };
            this.customTimeRange[value] = time;
            this.customUnitAlertEvents(value, 'new');
          }else{
            this.customUnitAlertEvents(value, 'duplicate');
          }          
        }else{        
          customTimeObject[unit] = value;
          const time = { name: value,  start_time: this.customTimeStart,  end_time: this.customTimeEnd};
          this.customTimeRange[value] = time;
          this.customUnitAlertEvents(value, 'new');
        }
        this.$data[unit] = value;
        this.customTimeObject = customTimeObject;
        this.customTimeValue = '';
        this.currentCustomKey = '';
      }else{        
        if(this.customTimeStart == null || this.customTimeEnd == null)
          this.customUnitAlertEvents(value, 'missing');
        if(value == 0)
          this.customUnitAlertEvents(value, 'name');
      }
    },
    handleCustomChange(unit){
      this.addCustomUnit(unit);
    },
    closeCustomUnits(unit){
      const unitValue = this.$data[unit];
      if(unitValue && unitValue == '+ Add'){
        this.$data[unit] = null;
        this.customField = '';
        this.currentCustomKey = '';
      }
    },
    getKeysWithSameValue(obj1, obj2) {
      let sameKeysAndValues = [];
      if(typeof obj1 == 'object' && typeof obj2 == 'object'){      
        const lastMetaDataKeys = Object.keys(obj1);
        const currentMetaDataKeys = Object.keys(obj2);
        let matchingKeys = lastMetaDataKeys.filter(e => currentMetaDataKeys.indexOf(e) !== -1);
        for (let key of matchingKeys) {
          if (obj1[key] === obj2[key]) {
            let matchingKeyValue = {};
            matchingKeyValue[key] = obj2[key];
            sameKeysAndValues.push(matchingKeyValue);
          }
        }
      }
      return sameKeysAndValues;
    },
    isAddingCustomUnits(unit){
      const unitValue = this.$data[unit];
      if(unitValue && unitValue == '+ Add'){
        this.currentCustomKey = unit;
        return true;
      }
      return false;
    },
    getSeasonsOrTime(unit){
      let currentUnitList = this.seasonList;
      let customObject = this.customSeasonsObject;
      let customList = [];
      if(unit == 'time'){
        customObject = this.customTimeObject;
        currentUnitList = this.timeList;
      }
      for (const [key, value] of Object.entries(customObject)) {
        if(key == unit){
          let unitsToAppend = value.split('_');
          customList = [].concat(unitsToAppend, customList);
          this.currentlyBeingAdded = unit;
        }
      }
      if(!customList.includes('+ Add') && this.currentCustomKey == ''){
        customList.push('+ Add');
      } 
      customList = [].concat(currentUnitList, customList);
      return  customList;
    },
    getUnitList(unit) {
      // get the default values of the corresponding unit list (currentUnitList)
      let currentUnitList = this.$data[unit];
      // remove the  last 4 letters of the "unit"
      unit = unit.slice(0, -4);
      let customUnitObject = this.customUnitObject;
      let customUnitList = []; 
      for (const [key, value] of Object.entries(customUnitObject)) {
        if(key == unit){
          let unitsToAppend = value.split('_');
          customUnitList = [].concat(unitsToAppend, customUnitList);
          this.currentlyBeingAdded = unit;
        }
      }
      if(!customUnitList.includes('+ Add') && this.currentCustomKey == ''){
        customUnitList.push('+ Add');
      } 
      customUnitList = [].concat(currentUnitList, customUnitList);
      return  customUnitList;
    },
    initializePopulatedFields(populatedFields){
      this.resetModalState();
      for (const [key, value] of Object.entries(populatedFields)) {
        if(key == 'wind_direction'){
          this.windDirection = value;
        }else if(key == 'subsurface_type'){
          this.presentationSurface = value;
        }else if(key == 'analysis_type'){
          this.analysisType = value;
        }else if(key == 'season_range'){
          this.seasonStart = value.start_month;
          this.seasonEnd = value.end_month;
        }else if(key == 'time_range'){
          this.timeStart = value.start_time;
          this.timeEnd = value.end_time;
        }else if(key == 'layer_type'){
          this.layerType = value;
        }else{
          this.$data[key] = value;
        }
      }
    },
    populateMultiple(){
      // Step 1 of 3: initialize the params needed  for both usecases (multiple files with metaData on a single file)
      let filesWithMetaData = this.getFilesWithMetaData();
      const uploads = this.gltfUploads;     
      let populatedFields = {};

      // Step 2 of 3: conditional check for single usecase as mentioned in step 1.
      if(filesWithMetaData.length == 1){  
      //passes the index of the file with metaData to populateSingle
        this.populateSingle(filesWithMetaData[0]);
        return;
      }

      // Step 3 of 3 : conditional check for single multiple files with metaData as mentioned in step 1.
      // logic used to compare fields between the multiple files(lastIndex vs currentIndex) 
      for (let index = 0; index < filesWithMetaData.length; index++) {  
        const currentIndex = filesWithMetaData[index];
        // there must be at least 2 files with metaData for the auto-populated fields to calculated
        if(index > 0){
          // filesWithMetaData's index is used to find the current file's meta data 
          let currenFileWithMetaData = uploads[currentIndex].metaData;
          const subtractIndex = index - 1;
          // filesWithMetaData's last index is used to find the last file's meta data 
          const lastIndex = filesWithMetaData[subtractIndex];
          let lastFileWithMetaData = uploads[lastIndex].metaData;
          // get the keys with the same values via the getKeysWithSameValue method
          const keysWithSameValues = this.getKeysWithSameValue(lastFileWithMetaData,currenFileWithMetaData);

          // logic for handling initializing initialpopulatedFields
          // if keysWithSameValue isnt empty and its the start of the loop
          if(keysWithSameValues.length > 0  && index == 1 ){
            //convert the array of objects to one large object
            let initialpopulatedFields = {};
            for (let keyIndex = 0; keyIndex < keysWithSameValues.length; keyIndex++) {
              const currentObject = keysWithSameValues[keyIndex];
              initialpopulatedFields = {...initialpopulatedFields, ...currentObject};
            }
            //set matching keys
            populatedFields = initialpopulatedFields;
          }

          // if keysWithSameValue isnt empty and the loop has already been initialized 
          if(keysWithSameValues.length > 0  && index != 1 ){  
          // logic for handling attribute comparision  between last studyFile vs current studyFile 
            for (let keyIndex = 0; keyIndex < keysWithSameValues.length; keyIndex++) {
              const currentObject = keysWithSameValues[keyIndex];
              let currentObjectKey = Object.keys(currentObject);
              currentObjectKey = currentObjectKey[0];
              //checks if the current studyFile's attribute is found in the last studyFile's attribute
              if(populatedFields[currentObjectKey] !== undefined){
              //if an attribute was found then compare the value
                if(currentObject[currentObjectKey] != populatedFields[currentObjectKey]){
                  // if the value of the attribute was not the same delete the attribute from populatedFields
                  delete populatedFields[currentObjectKey];
                }
              }
            }
          }
        }
      }
      // populate form fields in the modal
      if(Object.keys(populatedFields).length > 0){
        this.initializePopulatedFields(populatedFields);
      }
    },
    populateSingle(specialCase=null) {
      // initiate selectedFile with the only index in the list of selected files
      let selectedFile = this.selectedFiles[0];
      if(this.dbClicked !=null)
        // if the populateSingle was triggered with a double click initialize selectedFile that instead
        selectedFile = this.dbClicked;
      const uploads = this.gltfUploads;
      
      if(specialCase != null)
        selectedFile = specialCase;

      if(uploads[selectedFile].metaData){
        const populatedFields = uploads[selectedFile].metaData;
        this.initializePopulatedFields(populatedFields);
      }else{
        this.resetModalState();
      }
    },
    isMetaDataSet(index){ 
      let file = this.gltfUploads[index];
      if(file?.metaData){
        return true;
      }
      return false;
    },
    performBulkAction() {
      this.$bvModal.show('studyFileModal');
    }, 
    validateFileType(context) {
      if (context?.value?.files) {
        let result = [];
        let fileNames = [];
        let existingFileNames = this.fileNames;
        let duplicateFileNames = [];
        for (const file of context.value.files) {
          let file_extension = file.name.match(new RegExp('[^.]+$'))[0];
          if ( file_extension.toLowerCase() !== 'gltf' && file_extension.toLowerCase() !== 'glb') {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }else{
            if(file?.uuid && file?.path){
              const uuid = file.uuid;
              let file_context = file.path;
              const fileName = String(file.name);
              let words = fileName.split('.');
              words.pop(); //remove the file extension
              let filename_without_extension = words.join('.');
              if(!fileNames.includes(filename_without_extension) || !existingFileNames.includes(filename_without_extension)){
                if(filename_without_extension){
                  fileNames.push(filename_without_extension);
                  file_context['name'] = filename_without_extension;
                }else{
                  return false;
                }
                file_context['uuid'] = uuid;
                result.push(file_context);
              }else{
                if(!duplicateFileNames.includes(fileName))
                  duplicateFileNames.push(fileName);
              }
            }            
          }        
        }

        this.gltfUploads = result;
        this.fileNames = fileNames;
        this.duplicateFileNames = duplicateFileNames;
      }
      return true;
    },
    getGLTFParams() {
      // required fields 
      let details = {
        layer_type: this.layerType,
        analysis_type: this.analysisType,
      };
      if(this.windDirection != null)
        details['wind_direction'] = this.windDirection;
      if(this.metric != null){
        details['calculation'] = this.metric;
        details['metric'] = this.metric;
      }
      if(this.presentationSurface != null)
        details['subsurface_type'] = this.presentationSurface;

      // check optional values
      const season = this.customSeasonRange[this.season];
      const time = this.customTimeRange[this.time];
      if(season?.name && season?.start_month && season?.end_month){
        const season_range = { name: season.name,  start_month: season.start_month,  end_month: season.end_month };
        this.season_range = season_range;
        details['season_range'] = season_range;
        details['season'] = season_range.name;
      }
      if(time?.start_time && time?.start_time && time?.end_time){
        const time_range = { name: time.name,  start_time: time.start_time,  end_time: time.end_time };
        this.time_range = time_range;
        details['time_range'] = time_range;
        details['time'] = time_range.name;
      }
      return details;
    },
    submitGLTFDetails() {
      let details = this.getGLTFParams();
      let uploads = this.gltfUploads;
      let fileIndexes = this.selectedFiles;
      for (let index = 0; index < fileIndexes.length; index++) {
        // set metaData + required values
        const element = fileIndexes[index];
        uploads[element]['metaData'] = details;
        let manifest = {
          assets: {
            '.gltf': uploads[element].file
          },
          scenario: 'default',
          layer_type: this.layerType,
          analysis_type: this.analysisType,
          color_map: this.colorMap,
        };   
        // reuse details to reduce footprint
        let mergedManifest = {
          ...details,
          ...manifest
        };
        let qualifiersList = this.formatQualifiers();
        if(Object.keys(qualifiersList).length > 0 ){
          mergedManifest['qualifiers'] = qualifiersList;
        }
        uploads[element]['manifest'] = mergedManifest;
      }
      this.gltfUploads = uploads;
      this.mapQualifiers();
      this.$bvModal.hide('studyFileModal');
      this.checkUniqueValues(); 
      this.calculateSubmitStatus();
    },
    resetModalState(){
      this.layerType = null;
      this.analysisType = null;
      this.metric = null;
      this.presentationSurface = null;
      this.time = null;
      this.windDirection = null;
      this.season = null;
      this.seasonStart = null;
      this.seasonEnd = null;
      this.timeStart = null;
      this.timeEnd = null;
      this.populatedFields = {};
    },
    calculateSubmitStatus(){
      this.validUploads = this.getValidUploads();
      if(this.isValid){
        this.$emit('setSubmitStatus', true);
        this.$emit('setmetaData', this.gltfUploads);
      }else{
        let msg = 'Please fix file errors'; 
        if(this.gltfUploads.length == 0)
          msg = 'Please upload a study file';
        if(this.duplicatesList.length > 0)
          msg = 'Please remove duplicate meta data';
        this.$emit('setSubmitStatus', msg);
      }
    }
  },
  watch: {
    customQualifierValue(newValue){
      let customField = newValue.trim();
      this.qVal = this.validateQualifierEntry(customField);
    },
    customQualifierKey(newValue){
      let customField = newValue.trim();
      this.qKey = this.validateQualifierEntry(customField);
    },
    season(newValue){
      // conditional check for empty values and '+ Add'
      newValue = String(newValue).trim();
      if (newValue == '+ Add' || newValue.length == 0){
        this.addingSeasons = true;
      }else{
        this.addingSeasons = false;
      }
      // loads the start and end months for custom seasons    
      if(this.customSeasonRange[newValue]){
        const season = this.customSeasonRange[newValue];
        this.seasonStart = season.start_month;
        this.seasonEnd = season.end_month;
      }
    },
    time(newValue){
      // conditional check for empty values and '+ Add'
      newValue = String(newValue).trim();
      if (newValue == '+ Add' || newValue.length == 0){
        this.addingTime = true;
      }else{
        this.addingTime = false;
      }
      // loads the start and end times for custom time    
      if(this.customTimeRange[newValue]){
        const time = this.customTimeRange[newValue];
        this.timeStart = time.start_time;
        this.timeEnd = time.end_time;
      }
    },
    gltfUploads(newValue, oldValue){
      if(newValue != oldValue){
        for (let index = 0; index < newValue.length; index++) {
          const file = newValue[index];
          const columnLength = this.columnWidths[0];
          const scalingFactor = 10.3;
          const sum = file.name.length * scalingFactor;            
          if(columnLength && sum > columnLength ){
            // index 0 of columnWidths is for fileName
            this.columnWidths[0] = sum;      
          }
        }
      }
      this.calculateSubmitStatus();
    }
  }
};
</script>
<style scoped>
  .import-results-container {
    padding: 1.25em;
    margin: 0;
    position: absolute;
    height: 65%;
    width: 87%;
    top: calc(25% - 0.313rem);
    background-color: var(--grey-100);
    left: 5%;
    border-top-right-radius: 1.563rem;
    border-top-left-radius: 1.563rem;
    overflow-y: scroll;
    z-index: 2;
  }
</style>
<style>
  #qualifiers-list svg{
    font-size:0.813em;
  }
  #qualifiers-dropdown  > ul.dropdown-menu {
    width: 21.875em !important;
    overflow-x: scroll !important;
  }
  .time-season-width{
    width: 6.95em !important;
  }
  .file-select{
    width: 15.3125em !important;
  }
  #qualifiers-dropdown button{
    padding: 0 !important;
    border-color: #343a40 !important;
  }
  #select-checkmark div.formulate-input-element > label {
    margin: 0.25em 0 0 0 !important;
  }
  #q-list-items, #q-list-items:hover{
    text-decoration: none !important;  
    color: initial !important;
    font-size: small !important;
    padding: 0.25em !important;
  }
  #qualifiers-dropdown ul > li a.dropdown-item{
      background-color: transparent !important;
      padding: 0.25em !important;
  }
  #qualifiers-dropdown ul{
    margin: 0 !important;
    padding: 0 !important;  
  }
  #season-custom{
    width: 6.95em;
  }

  #time-custom_label{
    text-align: left;
  }
  .custom-input-name{
    text-align: -webkit-center !important;  
  }
  .custom-wrapper ul.formulate-input-errors{
    position: absolute !important;
  }
  .time-wrapper select{
    width:  6.95em !important;
    margin-right: 2% !important;
  }
  #add-custom-criteria-controls{
    z-index: 99999999;
    margin-left: 0.063em;
    margin-right: -0.438em;
  }
 .required-metadata-fields > div > ul > li{ 
    text-align: -webkit-center !important;  
    margin-right: 2.25% !important;
    margin-bottom: 0 !important;
  }
  .meta-row:hover{
    background-color: rgba(0, 0, 0, 0.075) !important; 
    cursor:pointer !important;
    color: #212529 !important;
  }
  .selected-row{
    background-color: rgba(0, 0, 0, 0.075) !important; 
  }
 .required-metadata-fields > .formulate-input > .required-metadata-fields{
    display: flex !important;
    justify-content: center !important;
  }
</style>
